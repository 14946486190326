
const baseurl="https://shmy0ykpgi.execute-api.ap-south-1.amazonaws.com/prod"
// const baseurl="https://37rvr69nce.execute-api.ap-south-1.amazonaws.com/Prod/"
// const baseurl="http://localhost:8000"
// const baseurl="http://localhost:8000"
const toi=["Fees","Services"]
const types=["Lumpsum","Percentage","On Sqft"]
const banknames=['Misc','Bandhan-20100018657972','Bharat-612100014610','Bharat-610100084505','DNS-29010100001263','HDFC-1451050122678','HDFC-501000174801181']
const ProjectStatus=["Active","Inactive","Working","Working Active","Pre-Appointment","Completed"]
const Agency=['SRA','Mhada','General','PMC']
const companies=["Neo","BZ","PMC","VBCPL","VBAPL"]
const tds=['No TDS Cut','TDS cut but not Paid',"TDS Claimed"]
const gst=['GST not Charged',"GST Charged to be Paid","GST Paid","GST to be Claimed","GST Claimed"]
const wards=["A","B","C","D","E","F/N","F/S","G/N","G/S","H/E","H/W","K/E","K/W","L","M/E","M/W","N","P/N","P/S","R/N","R/S","R/C","S","T"]
let Acknowledgement=["Not Required","Uploaded","Yet to be Uploaded"]
export {baseurl,toi,ProjectStatus,banknames,types,Agency,tds,gst,wards,companies,Acknowledgement}

// https://jiycm07tpk.execute-api.ap-south-1.amazonaws.com/prod