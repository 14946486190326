import React, { useState, useEffect } from "react";
import axios from "axios";
import { faHome, faQuran, faTrash, faAngleLeft, faAngleRight, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Col, Row, Form, Card, Button, Table, Container, InputGroup, Modal, Tab, Nav } from '@themesberg/react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseurl, ProjectStatus, companies } from "../../api";
import { triggerFunction, getPredefinedUrl } from '../../components/SignedUrl';
import { useHistory, useParams } from 'react-router-dom';
import { check } from '../../checkloggedin';
import Multiselect from "../../components/Multiselect";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAsyncData } from '../../features/userslice'
import { deletetasks } from "../../features/taskslice";
import { addtaskhistory } from "../../features/taskhistoryslice";
import AddCorrHistory from "../components/AddCorrHistory";
import ViewCorrHistory from "../components/viewCorrHistory.js";
// import ViewTaskHistory from "../components/ViewTaskHistory";
import { fetchProjects } from "../../features/projectslice";
import { addcorrespondence, getcorrespondence, deletecorrespondence } from "../../features/correspondenceSlice";
import AddRefEncl from "../components/AddRefEncl.js";
import Swal from 'sweetalert2';


export default ({ initialRow = [] }) => {
  const param = useParams();
  const [pname, setPname] = useState('');
  const [people, setPeople] = useState('');
  const [pnamearr, setPnamearr] = useState([]);
  const [taskstatus, setTaskStatus] = useState('');
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [corr, setCorr] = useState([])

  // for edit
  const [taskid, seteditTaskid] = useState("")
  const [editassignTaskTo, setEditassignTaskTo] = useState([])

  const [editCorrid, setEditCorrid] = useState("")
  const [editprojectname, setEditprojectname] = useState("")
  const [edittaskDescription, setEdittaskDescription] = useState("")
  const [edittaskSubject, setEdittaskSubject] = useState("")
  const [editdate, seteditdate] = useState(null)
  const [editletterno, seteditletterno] = useState([])
  const [editMode, setEditMode] = useState(false);
  const [editFiles, setEditFiles] = useState([]);
  const [editFiles1, setEditFiles1] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDynamicModal, setShowDynamicModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(initialRow);
  const [editCompany, setEditCompany] = useState('');
  const [showModal3, setShowModal3] = useState(false);
  let [selectedFile, setSelectedFile] = useState(null);
  let [selectedFile1, setSelectedFile1] = useState(null);


  // project filtering
  let [companyname, setCompanyName] = useState('')
  let [isActive, setIsActive] = useState(null)

  // view task History
  const [history, setHistory] = useState([])
  const [taskthis, settaskthis] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  //view add History
  const [texthistory, setaddtexthistory] = useState("")
  const [showModal2, setShowModal2] = useState(false);
  const [projectname, setprojectname] = useState("")

  //Created Option 
  let [createdoption, setCreatedoption] = useState(0)


  // Created Correspondence
  let [corrdetails, setcorrdetails] = useState({})
  let [files, setfiles] = useState([])
  let [id, setid] = useState("")
  const [fileExtension, setFileExtension] = useState('')
  const [stop, setStop] = useState(true)
  const [editselectedusers, setEditSelectedusers] = useState([])
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [rows, setRows] = useState(initialRow);
  const [url1, setUrl1] = useState('');
  const [key1, setKey1] = useState('');
  const [url2, setUrl2] = useState('');
  const [key2, setKey2] = useState('');


  // common for all
  const dispatch = useDispatch();

  // for users
  const { user1, loading, error } = useSelector((state) => state.users);
  const { correspondence } = useSelector((state) => state.correspondence);

  const token = localStorage.getItem('token');

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.put(`${baseurl}/task/filter`, {
          projectid: pname || undefined,
          assignTaskTo: people ? [people] : undefined,
          taskCompleted: taskstatus || undefined
        });

        // Check if the response status is 200
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    })();

    dispatch(fetchAsyncData());

    if (user1.length !== 0) {
      setUsers(user1);
    }

    handleprojectFetch();
    console.log(window.location.href);
  }, [user1.length]);



  useEffect(() => {
    dispatch(getcorrespondence()).then((resp) => {
      setCorr(resp)
      // //////console.log(resp)
    }).catch(error => {
      console.log(error)
    })


  }, [])

  const getallcorrespondence = (corrid, from) => {

    dispatch(getcorrespondence()).then((resp) => {
      setCorr(resp)
      handleCorrhistory({ _id: corrid }, from)
      // //////console.log(resp)
    }).catch(error => {
      console.log(error)
    })
  }

  const handleCorrhistory = async (row, from) => {
    ////////////////console.log("hi")

    // console.log(row._id)
    let singlecorr = corr.find((value) => value._id == row._id)
    // console.log(singlecorr)
    files = singlecorr.files
    if (from == false) {
      setfiles(singlecorr.files)
    }
    setid(row._id)
    setShowModal1(true)
    settaskthis(true)
  }


  const handleprojectFetch = async () => {
    ////////////////console.log(companyname)
    dispatch(fetchProjects({
      company: companyname ? companyname : null,
      status: isActive ? isActive : null
    })).then((resp) => {
      setPnamearr(resp)
      //////console.log(resp)
    }).catch(error => {

    })
  }
  const findprojectname = (id) => {
    ////////////////console.log(id,pnamearr)
    for (let i = 0; i < pnamearr.length; i++) {
      if (pnamearr[i]._id === id) {
        return pnamearr[i].name
      }
    }
  }

  const handleFetch = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.put(`${baseurl}/task/filter`, {
        projectid: pname || undefined,
        assignTaskTo: people ? [people] : undefined,
        taskCompleted: taskstatus || undefined
      });
      setData(response.data);
      //////console.log(response.data)

    } catch (error) {
      //console.error(error);
    }
  };

  const getUsernameById = (assignTaskTo) => {
    let str = "";
    for (let i = 0; i < assignTaskTo.length; i++) {
      for (let j = 0; j < users.length; j++) {
        if (users[j]._id === assignTaskTo[i]) {
          str = str + users[j].username + " ";
          break;
        }
      }
    }
    return str;
  };


  const handleComplete = (id) => {
    // Find the task with the given id and toggle its completion status locally
    const updatedData = data.map(item => {
      if (item._id === id) {
        return { ...item, taskCompleted: !item.taskCompleted };
      }
      return item;
    });

    // Update the state with the modified data
    setData(updatedData);

    // Make the PUT request to update the task completion status on the server
    axios.put(`${baseurl}/task/complete/${id}`)
      .then(response => {
        // Handle success response if needed
      })
      .catch(error => {
        // If the request fails, revert the local state change
        //console.error(error);
        setData(data); // Revert back to the original data
      });
  };
  const handleEditModal = (item) => {
    // let temp = []
    // let tempuser = item.assignTaskTo
    // for (let j = 0; j < users.length; j++) {
    //   if ((tempuser).includes(users[j]._id)) {
    //     temp.push({
    //       id: users[j]._id,
    //       name: users[j].username,
    //     })
    //   }
    // }
    ////////////////console.log(temp,"hi")
    // seteditTaskid(item._id)
    // setEditassignTaskTo(temp)
    setEditCorrid(item._id)
    setEditprojectname(item.project)
    setEdittaskDescription(item.description)
    setEdittaskSubject(item.subject)
    seteditletterno(item.letterno)
    seteditdate(item.date)
    setEditFiles(item.files)
    setEditFiles1(item.files1)
    setShowModal3(true);
    setEditMode(true); // Set editMode to true when opening the edit modal
  }

  const handleaddhistory = async (row, projectname) => {
    // console.log(row)
    // row.projectname = projectname
    setcorrdetails(row)
    // seteditTaskid(row._id)
    setShowModal2(true)
    // dispatch(addtaskhistory("hi"))

  }
  const timeinIndia = (date) => {
    const utcTime = new Date(date);
    // console.log(utcTime)
    const istTime = utcTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
    return (istTime);
  }
  const sortbycreatedby = () => {
    // Increment the createdoption to toggle through sorting states
    let temp = createdoption + 1;
    if (temp > 2) {
      temp = 1; // Reset to 1 if it exceeds the number of sorting options
    }
    setCreatedoption(temp);

    // Make a copy of the data array to avoid mutating the state directly
    let sortedData = [...corr]; // Ensure corr is the state being rendered

    // Sort the data based on created date
    if (temp === 1) {
      // Ascending order: Oldest first
      sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
    } else if (temp === 2) {
      // Descending order: Newest first
      sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
    }

    setCorr(sortedData); // Update the state with the sorted data
  };



  const handleRefClick = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const handleDocumentClick = (document) => {
    const newcorr = corr.find((val) => val._id == document)
    //   const newRow = findprojectname(document); // Mock function to get the new row details based on the document ID or reference.
    //   if (newRow) {
    //     setShowDynamicModal((prevModals) => [...prevModals, document]);
    //   }
    //   cons
    setSelectedRow(newcorr); // Set the new row to be displayed in the modal
    setShowDynamicModal(true); // Re-open the modal with new details
  };

  const handleCloseDynamicModal = () => {
    setShowDynamicModal((prevModals) => prevModals.slice(0, - 1)); // Close the current dynamic modal
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name;
      setSelectedFile1(file);
      setFileExtension(fileExtension);

      try {
        const [url, key] = await triggerFunction(fileExtension, folderName);
        setUrl1(url); // For the first file
        setKey1(key); // For the first file
        setIsFileSelected(true);
      } catch (error) {
        console.error('Error triggering function:', error);
        toast.error('Failed to process the file');
      }
    } else {
      setSelectedFile1(null);
      setFileExtension('');
      setIsFileSelected(false);
    }
  };

  const handleFileChange1 = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name;
      setSelectedFile(file);
      setFileExtension(fileExtension);

      try {
        const [url, key] = await triggerFunction(fileExtension, folderName);
        setUrl2(url); // For the second file
        setKey2(key); // For the second file
        setIsFileSelected(true);
      } catch (error) {
        console.error('Error triggering function:', error);
        toast.error('Failed to process the file');
      }
    } else {
      setSelectedFile(null);
      setFileExtension('');
      setIsFileSelected(false);
    }
  };




  const handleEditSubmit = async (e) => {
    e.preventDefault();

    let file1UploadSuccess = true; // Assume success by default
    let file2UploadSuccess = true; // Assume success by default

    const fileUploadHandler = async (file, uploadUrl) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const fileContent = event.target.result;

          try {
            const responseFile = await fetch(uploadUrl, {
              method: 'PUT',
              body: fileContent,
              headers: {
                'Content-Type': 'application/octet-stream',
              },
              mode: 'cors',
            });

            if (!responseFile.ok) {
              throw new Error('Network response was not ok');
            }

            toast.success('File Uploaded Successfully');
            resolve(true); // Indicate success
          } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Failed to add image');
            resolve(false); // Indicate failure
          }
        };
        reader.readAsArrayBuffer(file);
      });
    };

    // Upload files and track success/failure
    if (selectedFile1) {
      file1UploadSuccess = await fileUploadHandler(selectedFile1, url1);
    }
    if (selectedFile) {
      file2UploadSuccess = await fileUploadHandler(selectedFile, url2);
    }

    // Only proceed if both file uploads succeeded
    if (!file1UploadSuccess || !file2UploadSuccess) {
      toast.error('File upload failed. Data update aborted.');
      return; // Stop further execution if any upload fails
    }

    const token = localStorage.getItem('token');

    // Create the files array by inserting new data at the next available index
    let files = [...editFiles];

    if (selectedFile1) {
      // Determine the next available index for the new file
      const nextIndex = files.length;

      files.push({
        order: nextIndex, // Use the next available index
        description: '', // You can set a default or gather this info as needed
        date: new Date().toISOString(),
        filename: selectedFile1.name,
        current: getPredefinedUrl(key1),
        prevlinks: [], // This can remain empty or store other necessary information
        isDisabled: false,
      });
    }

    // Create the files1 array by inserting new data at the next available index
    let files1 = [...editFiles1];

    if (selectedFile) {
      // Determine the next available index for the new file
      const nextIndex1 = files1.length;

      files1.push({
        order: nextIndex1, // Use the next available index
        description: '', // You can set a default or gather this info as needed
        date: new Date().toISOString(),
        filename: selectedFile.name,
        current: getPredefinedUrl(key2),
        prevlinks: [], // This can remain empty or store other necessary information
        isDisabled: false,
      });
    }

    const editData = {
      date: editdate,
      project: editprojectname,
      description: edittaskDescription,
      subject: edittaskSubject,
      files: files,
      files1: files1,
    };

    try {
      const response = await axios.put(
        `${baseurl}/correspondence/updatecorr/${editCorrid}`,
        editData,
        {
          headers: { Authorization: ` ${token}` },
        }
      );

      toast.success('Data updated successfully');
      setShowModal(false);
      handleprojectFetch();
      window.location.reload();
    } catch (error) {
      console.error('Error updating record:', error);
      toast.error('Failed to update record');
    }
  };






  // Inside your component or wherever you are handling the delete
  const handleDelete = async (id) => {
    console.log(`Disable button clicked for ID: ${id}`);

    try {
      // Await the dispatch to get the resolved result (true or false)
      const isUpdated = await dispatch(deletecorrespondence(id));

      if (isUpdated) {
        console.log('Correspondence disabled successfully');
        // Update the state to mark the row as disabled
        setRows((prevRows) =>
          prevRows.map((row) =>
            row._id === id ? { ...row, isDisabled: true } : row
          )
        );
      } else {
        console.error('Failed to disable correspondence');
      }
    } catch (error) {
      console.error('Error occurred during disable operation:', error);
    }
  };







  return (
    <>
      <ToastContainer />
      <form onSubmit={(e) => handleFetch(e)}>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group id="pname" className="mb-4">
              <Form.Label>Company Name</Form.Label>
              <InputGroup>
                <InputGroup.Text></InputGroup.Text>
                <Form.Select required value={editCompany} onChange={(e) => setEditCompany(e.target.value)}>
                  <option value="">Select Option</option>
                  {companies.map((company, index) => (
                    <option key={index} value={company}>
                      {company}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group id="taskstatus" className="mb-4">
              <Form.Label>Project Status</Form.Label>
              <InputGroup>
                <InputGroup.Text></InputGroup.Text>
                <Form.Select value={isActive} onChange={(e) => {
                  isActive = e.target.value
                  setIsActive(e.target.value)
                  handleprojectFetch()
                }}>
                  <option value="">Select Option</option>
                  {/* Mapping through the arr array to generate options */}
                  {ProjectStatus.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group id="pname" className="mb-4">
              <Form.Label>Project name</Form.Label>
              <InputGroup>
                <InputGroup.Text></InputGroup.Text>
                <Form.Select value={pname} onChange={(e) => setPname(e.target.value)}>
                  <option value="">Select Option</option>
                  pnamearr
                  {pnamearr != undefined ? pnamearr.map((option, index) => (
                    <option key={index} value={option._id}>{option.name}</option>
                  )) : null}
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col xs={12} md={4}>
            <Form.Group id="people" className="mb-4">
              <Form.Label>People</Form.Label>
              <InputGroup>
                <InputGroup.Text></InputGroup.Text>
                <Form.Select value={people} onChange={(e) => setPeople(e.target.value)}>
                  <option value="">Select Option</option>
                  {users.map((option, index) => (
                    <option key={index} value={option._id}>{option.username}</option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group id="taskstatus" className="mb-4">
              <Form.Label>Task Status</Form.Label>
              <InputGroup>
                <InputGroup.Text></InputGroup.Text>
                <Form.Select value={taskstatus} onChange={(e) => setTaskStatus(e.target.value)}>
                  <option value="">Select Option</option>
                  <option value={true}>Complete</option>
                  <option value={false}>Incomplete</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} md={2} className="d-flex justify-content-center">
            <Button style={{ height: "70%" }} variant="primary" type="submit" className="w-100 mt-3">
              Submit
            </Button>
          </Col>
        </Row>
      </form>
      <section>
        <Container>
          <Row>
            <Col className="mx-auto">
              <Card style={{ width: "120%", marginLeft: "-10%", paddingLeft: "5%" }} border="light" className="shadow-sm">
                <Card.Header>
                  <Row className="align-items-center">
                    <Col>
                      <h5>Service List</h5>
                    </Col>
                  </Row>
                </Card.Header>
                <Table responsive className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="unselectable" style={{ cursor: "pointer" }} onClick={sortbycreatedby}>
                        Created At
                      </th>
                      <th scope="col">Project Name</th>
                      <th scope="col">Reference to</th>
                      <th scope="col">Reference from</th>
                      <th scope="col">Enclosed to</th>
                      <th scope="col">Enclosed from</th>
                      <th scope="col">File Current</th>
                      <th scope="col">File1 Current</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {corr.length === 0 ? (
                      <tr>
                        <td colSpan="8" className="text-center">Loading...</td>
                      </tr>
                    ) : (
                      corr.map((row, index) => {
                        const projectName = findprojectname(row.project);
                        return projectName ? (
                          <tr key={index}>
                            <td style={{ whiteSpace: "pre-wrap" }}>{row.date}</td>
                            <td style={{ whiteSpace: "pre-wrap" }}>{projectName}</td>
                            {/* Reference To */}
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              <ul style={{ listStyleType: 'none' }}>
                                {row.refto && row.refto.map((document, index) => (
                                  <li key={index} style={{ marginBottom: '5px', paddingLeft: '20px', textIndent: '-15px' }}>
                                    • <a style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleRefClick(row)}>
                                      {document}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </td>
                            {/* Reference From */}
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              <ul style={{ listStyleType: 'none' }}>
                                {row.reffrom && row.reffrom.map((document, index) => (
                                  <li key={index} style={{ marginBottom: '5px', paddingLeft: '20px', textIndent: '-15px' }}>
                                    • <a style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleRefClick(row)}>
                                      {document}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </td>
                            {/* Enclosed To */}
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              <ul style={{ listStyleType: 'none' }}>
                                {row.refto && row.refto.map((document, index) => (
                                  <li key={index} style={{ marginBottom: '5px', paddingLeft: '20px', textIndent: '-15px' }}>
                                    • <a style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleRefClick(row)}>
                                      {document}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </td>
                            {/* Enclosed From */}
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              <ul style={{ listStyleType: 'none' }}>
                                {row.reffrom && row.reffrom.map((document, index) => (
                                  <li key={index} style={{ marginBottom: '5px', paddingLeft: '20px', textIndent: '-15px' }}>
                                    • <a style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleRefClick(row)}>
                                      {document}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </td>
                            {/* File Current */}
                            <td colSpan="1">
                              {row.files && row.files.length > 0 ? (
                                <pre style={{ whiteSpace: "pre-wrap" }}>
                                  {row.files.slice(-1).map((file, index) => (
                                    <a
                                      key={index}
                                      href={file.current} // Ensure to download the file at the last index
                                      download
                                      style={{ textDecoration: "underline", color: "blue" }}
                                      onClick={() => console.log("Downloading Appointment Letter from:", file.current)}
                                    >
                                      Appointment Letter
                                    </a>
                                  ))}
                                </pre>
                              ) : (
                                <span>No appointment letters available</span>
                              )}
                            </td>
                            {/* File1 Current */}
                            <td colSpan="1">
                              {row.files1 && row.files1.length > 0 ? (
                                <pre style={{ whiteSpace: "pre-wrap" }}>
                                  {row.files1.slice(-1).map((file, index) => (
                                    <a
                                      key={index}
                                      href={file.current} // Ensure to download the file at the last index
                                      download
                                      style={{ textDecoration: "underline", color: "blue" }}
                                      onClick={() => console.log("Downloading Acknowledgement Letter from:", file.current)}
                                    >
                                      Acknowledgement Letter
                                    </a>
                                  ))}
                                </pre>
                              ) : (
                                <span>No acknowledgement letters available</span>
                              )}
                            </td>
                            <td>
                              <Button style={{ backgroundColor: "aqua", color: "black" }} variant="info" size="sm" onClick={() => handleEditModal(row)}>
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                              <Button style={{ borderColor: "black", backgroundColor: "aqua", color: "black", marginLeft: "2%" }} onClick={() => handleDelete(row._id)} variant="danger" size="sm">
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                              <Button style={{ backgroundColor: "aqua", color: "black", marginLeft: "2%" }} onClick={() => handleaddhistory(row, projectName)}>Add</Button>
                              <Button
                                style={{ backgroundColor: "aqua", color: "black", marginLeft: "2%" }}
                                onClick={() => handleComplete(row._id)}
                              >
                                {row.taskCompleted ? "Mark incomplete" : "Mark complete"}
                              </Button>
                            </td>
                          </tr>
                        ) : null;
                      })
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

          {/* Dynamic Modal */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Row Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedRow && (
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Created At:</strong></td>
                      <td>{timeinIndia(selectedRow.date)}</td>
                    </tr>
                    <tr>
                      <td><strong>Project Name:</strong></td>
                      <td>{findprojectname(selectedRow.project)}</td>
                    </tr>
                    <tr>
                      <td><strong>Subject:</strong></td>
                      <td>{selectedRow.subject}</td>
                    </tr>
                    <tr>
                      <td><strong>ID:</strong></td>
                      <td>{selectedRow._id}</td>
                    </tr>
                    <tr>
                      <td><strong>Description:</strong></td>
                      <td>{selectedRow.description}</td>
                    </tr>
                    {/* Map through all files and display */}
                    <tr>
                      <td><strong>Previous Appointment Letters:</strong></td>
                      <td>
                        <ul style={{ listStyleType: 'none' }}>
                          {selectedRow.files && selectedRow.files.slice(0, -1).map((file, index) => (
                            <li key={index}>
                              {file.current ? (
                                <a href={file.current} download style={{ textDecoration: "underline", color: "blue" }}>
                                  {`File ${index + 1}`} - {file.date}
                                </a>
                              ) : "No file available"}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Previous Acknowledgement Letters:</strong></td>
                      <td>
                        <ul style={{ listStyleType: 'none' }}>
                          {selectedRow.files1 && selectedRow.files1.slice(0, -1).map((file, index) => (
                            <li key={index}>
                              {file.current ? (
                                <a href={file.current} download style={{ textDecoration: "underline", color: "blue" }}>
                                  {`File ${index + 1}`} - {file.date}
                                </a>
                              ) : "No file available"}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Appointment Letters:</strong></td>
                      <td colSpan="1">
                        {selectedRow.files && selectedRow.files.length > 0 ? (
                          <pre style={{ whiteSpace: "pre-wrap" }}>
                            {selectedRow.files.slice(-1).map((file, index) => (
                              <a
                                key={index}
                                href={file.current} // Ensure to download the file at the last index
                                download
                                style={{ textDecoration: "underline", color: "blue" }}
                                onClick={() => console.log("Downloading Appointment Letter from:", file.current)}
                              >
                                Appointment Letter
                              </a>
                            ))}
                          </pre>
                        ) : (
                          <span>No appointment letters available</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Acknowledgement Letters:</strong></td>
                      <td colSpan="1">
                        {selectedRow.files1 && selectedRow.files1.length > 0 ? (
                          <pre style={{ whiteSpace: "pre-wrap" }}>
                            {selectedRow.files1.slice(-1).map((file, index) => (
                              <a
                                key={index}
                                href={file.current} // Ensure to download the file at the last index
                                download
                                style={{ textDecoration: "underline", color: "blue" }}
                                onClick={() => console.log("Downloading Acknowledgement Letter from:", file.current)}
                              >
                                Acknowledgement Letter
                              </a>
                            ))}
                          </pre>
                        ) : (
                          <span>No acknowledgement letters available</span>
                        )}
                      </td>
                    </tr>
                    {/* References */}
                    <tr>
                      <td><strong>Reference to:</strong></td>
                      <td>
                        <ul style={{ listStyleType: 'none' }}>
                          {selectedRow.refto && selectedRow.refto.map((document, index) => (
                            <li key={index} onClick={() => handleDocumentClick(document)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                              {document}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Reference from:</strong></td>
                      <td>
                        <ul style={{ listStyleType: 'none' }}>
                          {selectedRow.reffrom && selectedRow.reffrom.map((document, index) => (
                            <li key={index} onClick={() => handleDocumentClick(document)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                              {document}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    {/* Enclosed */}
                    <tr>
                      <td><strong>Enclosed to:</strong></td>
                      <td>
                        <ul style={{ listStyleType: 'none' }}>
                          {selectedRow.refto && selectedRow.refto.map((document, index) => (
                            <li key={index} onClick={() => handleDocumentClick(document)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                              {document}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Enclosed from:</strong></td>
                      <td>
                        <ul style={{ listStyleType: 'none' }}>
                          {selectedRow.reffrom && selectedRow.reffrom.map((document, index) => (
                            <li key={index} onClick={() => handleDocumentClick(document)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                              {document}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </section>
      {/* edit modal */}
      <Modal show={showModal3} onHide={() => setShowModal3(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formProjectName">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                value={editprojectname}
                onChange={(e) => setEditprojectname(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTaskSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={edittaskSubject}
                onChange={(e) => setEdittaskSubject(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTaskDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={edittaskDescription}
                onChange={(e) => setEdittaskDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                value={editdate}
                onChange={(e) => seteditdate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFiles">
              <Form.Label>Files</Form.Label>
              <Form.Control
                type="file"
                multiple
                onChange={handleFileChange}
              />
              <Form.Text className="text-muted">
                Current files: {editFiles.map(file => file.name).join(', ')}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formFiles1">
              <Form.Label>Additional Files</Form.Label>
              <Form.Control
                type="file"
                multiple
                onChange={handleFileChange1}
              />
              <Form.Text className="text-muted">
                Additional files: {editFiles1.map(file => file.name).join(', ')}
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal3(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>


      <ViewCorrHistory resett={getallcorrespondence} id={id} history={files} setHistory={setfiles} showModal1={showModal1} setShowModal1={setShowModal1} />
      {/* add history */}

      {/* <AddCorrHistory corr={corrdetails} showModal2={showModal2} setShowModal2={setShowModal2} /> */}

      <AddRefEncl corr={corrdetails} allcorr={corr} setcorr={setCorr} showModal2={showModal2} setShowModal2={setShowModal2} />



    </>
  );
}


